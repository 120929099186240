@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800;900&display=swap');

html {
  font-size: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  height: 100%;
  font-family: 'Inter', Helvetica, Verdana, sans-serif;
  color: #515359;
  letter-spacing: 0px;
  overflow: hidden
}

strong {
  font-weight: 600;
}

button:disabled {
  opacity: 0.5;
}

#root {
  height: 100%;
}